import React from "react"
import ContactPage from "../components/Contact/ContactPage"
import useSiteMetadata from "../components/graphqlQueries/SiteMetadata"
import Seo from "../components/Seo/Seo"

export default function Success({ location }) {
  const { siteUrl } = useSiteMetadata()
  return (
    <>
      <Seo title="Message Received" />
      <ContactPage href={`${siteUrl}${location.pathname}`} />
    </>
  )
}
